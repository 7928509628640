import React from 'react';
import {  KadaFilter } from 'kada-component-library';
import axiosSolr from '../../../../axios-solr';

const LevelFilter = props => {

  const {
    levelFilter,
    setLevelFilter,
    levelFilterList,
    setLevelFilterList,
    loadHistory,
  } = props;


  const onChangeLevelFilter = (value) => {
    if (value === levelFilter) return;
    setLevelFilter(value);
    loadHistory({ start: 0, level: value });
  }

  const loadLevelFilterList = ({ start = 0 }) => {
    axiosSolr
      .post(
        `/solr/impact_config/select`, {
        params: {
          q: "*",
          fq: `*`,
          rows: 0,
          'json.facet': {
            "assessment_level": {
              type: "terms",
              field: "assessment_level_srt",
              limit: 50,
              offset: start,
              sort: "index asc",
            }
          }
        }
      }
      )
      .then(async res => {
        let buckets = res.data.facets.assessment_level.buckets;
        let list = [];

        buckets.forEach(el => {
          list.push({
            text: el.val,
            value: el.val,
          })
        })

        setLevelFilterList([...(levelFilterList || []), ...list]);
      })
  }

  return (
    <KadaFilter
      placeholder='All'
      value={levelFilter}
      isHidePlaceholderMenuItem
      isHideSearch
      valueDisplayPrefix='Assessment Level: '
      options={
        [
          { text: 'All', value: 'All' },
          ...(levelFilterList || [])
        ]
      }
      onClick={() => {
        if (!levelFilterList) {
          loadLevelFilterList({ start: 0 });
        }
      }}
      onlyTriggerScrollOnBottom
      onScroll={() => {
        if (levelFilterList?.length > 0 && levelFilterList?.length % 50 === 0) {
          loadLevelFilterList({ start: levelFilterList.length });
        }
      }}
      onChange={e => {
        onChangeLevelFilter(e.detail.value)
      }}
    />
  )
}

export default LevelFilter;
