import React, { useContext } from 'react';
import { IAActionTypes, IAContext } from '../../../../containers/ImpactAssessmentV2/ImpactAssessmentV2';
import {  KadaFilter } from 'kada-component-library';
import axiosSolr from '../../../../axios-solr';
import { getIconLabel } from '../../../UI/SearchResults/utils';

const TargetFilter = props => {

  const {
    state,
    dispatch,
  } = useContext(IAContext);

  const {
    targetFilter,
    setTargetFilter,
    targetFilterList,
    setTargetFilterList,
    loadHistory,
  } = props;

  const onChangeTargetFilter = (value) => {
    if (value === targetFilter) return;
    setTargetFilter(value);
    dispatch({
      type: IAActionTypes.SET_TARGET_OBJECT,
      targetObject: targetFilterList.find(el => el.value === value)?.object
    })
    const url = new URL(window.location.href);
    const params = new URLSearchParams(url.search);
    if (value === 'All') {
      params.delete('targetObjectId');
    } else {
      params.set('targetObjectId', value);
    }
    url.search = params.toString();
    window.history.replaceState({}, '', url);
    loadHistory({ start: 0, targetID: value });
  }

  const loadTargetFilterList = ({ start = 0 }) => {
    axiosSolr
      .post(
        `/solr/impact_config/select`, {
        params: {
          q: "*",
          fq: `*`,
          rows: 0,
          'json.facet': {
            "target_object_id": {
              type: "terms",
              field: "target_object_id",
              limit: 50,
              offset: start,
              sort: "count desc",
              facet: {
                "name": {
                  type: "terms",
                  field: "target_object_name_srt",
                  limit: 1,
                },
                "location": {
                  type: "terms",
                  field: "target_object_location_srt",
                  limit: 1,
                },
                "object_type": {
                  type: "terms",
                  field: "target_object_type_srt",
                  limit: 1,
                },
              }
            }
          }
        }
      }
      )
      .then(async res => {
        let buckets = res.data.facets.target_object_id.buckets;
        let list = [];

        buckets.forEach(el => {
          let name = el.name.buckets?.[0]?.val || el.val;
          let location = el.location.buckets?.[0]?.val || '';
          let objectType = el.object_type.buckets?.[0]?.val || '';
          list.push({
            text: name,
            subText: location,
            icon: objectType,
            value: el.val,
            object: {
              id: el.val,
              object_type_txt: objectType,
              name_txt: name,
              location_txt: location,
              description: ''
            }
          })
        })

        setTargetFilterList([...(targetFilterList || []), ...list]);
      })
  }

  return (
    <KadaFilter
      placeholder='All'
      value={targetFilter}
      isHidePlaceholderMenuItem
      isHideSearch
      valueDisplayPrefix='Target: '
      options={
        [
          { text: 'All', value: 'All' },
          (
            state.targetObject?.id && (!targetFilterList || !targetFilterList.find(el => el.value === state.targetObject?.id)) ?
              {
                text: `${state.targetObject?.name_txt}`,
                subText: `${state.targetObject?.location_txt}`,
                icon: getIconLabel({ label: state.targetObject?.object_type_txt, item: state.targetObject }),
                value: state.targetObject?.id
              }
              :
              undefined
          ),
          ...(targetFilterList || [])
        ].filter(el => el !== undefined)
      }
      onClick={() => {
        if (!targetFilterList) {
          loadTargetFilterList({ start: 0 });
        }
      }}
      onlyTriggerScrollOnBottom
      onScroll={() => {
        if (targetFilterList?.length > 0 && targetFilterList?.length % 50 === 0) {
          loadTargetFilterList({ start: targetFilterList.length });
        }
      }}
      onChange={e => {
        onChangeTargetFilter(e.detail.value)
      }}
    />
  )
}

export default TargetFilter;
