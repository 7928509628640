import React, { startTransition, useContext, useState } from 'react';
import { IAContext } from '../../../containers/ImpactAssessmentV2/ImpactAssessmentV2';
import BasicSearch from '../../../containers/BasicSearch/BasicSearch';
import { KadaBtn, kButtonVariants } from 'kada-component-library';
import { onClickResultItem } from '../../../utilities';

const DrillThroughResult = props => {

  const {
    hiddenComponents,
    parentGenericFilter
  } = props;

  const {
    history,
    state,
  } = useContext(IAContext);

  const [userSearchCache, setUserSearchCache] = useState();
  const [sourceSearchCache, setSourceSearchCache] = useState();

  const [view, setView] = useState('user');


  return (
    <div>
      <div className="flex items-center mb-6 flex-wrap gap-4">
        <p className='ml-4 w-28'>
          Impact view
        </p>
        <KadaBtn
          text="Impacted users"
          variant={view === 'user' ? kButtonVariants.primarySelected : kButtonVariants.primaryOutlined}
          onClick={() => {
            startTransition(() => {
              setView('user')
            })
          }}
          size="s"
        />
        <KadaBtn
          text="Impacted sources"
          variant={view === 'source' ? kButtonVariants.primarySelected : kButtonVariants.primaryOutlined}
          onClick={() => {
            startTransition(() => {
              setView('source')
            })
          }}
          size="s"
        />
      </div>

      {
        view === 'user' &&
        <div
          tabIndex={0}
          className={`collapse overflow-visible collapse-open`}
        >
          <div className="collapse-title flex items-center !cursor-default">
            <div className="flex items-center flex-wrap gap-4">
              <p>Impacted Users:</p>
            </div>
          </div>
          <div className="collapse-content overflow-hidden">
            <BasicSearch
              key={'user_view'}
              initialView={'main_search'}
              indexName={'impact'}
              history={history}
              alwaysOpenNewTab={true}
              removeContainerStyle={true}
              propObjectType={'ALL'}
              joinFqs={[
                `{!child of=parent:true filters=$parent_filters}{!join fromIndex=search from=id to=impact_object_id v=$asset_filters}`,
                '{!collapse field=id}'
              ]}
              propColumn="user_job_type_msrt,user_teams_msrt"
              joinVarParams={{
                asset_filters: `id:${state.drillThroughObject?.id}`,
                parent_filters: parentGenericFilter
              }}
              disableAutoScrollOnTabChange
              disableDefaultFilters
              resultItemVariant={"simplified"}
              hiddenComponents={hiddenComponents}
              propQuery="*"
              propCache={userSearchCache}
              propStoreCache={cache => {
                setUserSearchCache(cache);
              }}
              propSelectedFilters="user_job_type_msrt,user_teams_msrt"
              forceOnItemClick={el => {
                onClickResultItem({
                  item: el,
                  id: el.id,
                  label: 'user',
                  newWindow: true
                })
              }}
            />
          </div>
        </div>
      }

      {
        view === 'source' &&
        <div
          tabIndex={0}
          className={`collapse overflow-visible collapse-open`}
        >
          <div className="collapse-title flex items-center !cursor-default">
            <div className="flex items-center flex-wrap gap-4">
              <p>Impacted Sources:</p>
            </div>
          </div>
          <div className="collapse-content overflow-hidden">
            <BasicSearch
              key={'source_view'}
              initialView={'main_search'}
              indexName={'search'}
              history={history}
              alwaysOpenNewTab={true}
              removeContainerStyle={true}
              propObjectType={'ALL'}
              joinFqs={[
                "{!join fromIndex=impact from=starting_object_id to=id v=$asset_filters}{!parent which=$parent_filter}",
              ]}
              joinVarParams={{
                parent_filter: parentGenericFilter,
                asset_filters: `impact_object_id:${state.drillThroughObject?.id}`,
              }}
              disableAutoScrollOnTabChange
              disableDefaultFilters
              resultItemVariant={"simplified"}
              hiddenComponents={hiddenComponents}
              propQuery="*"
              propCache={sourceSearchCache}
              propStoreCache={cache => {
                setSourceSearchCache(cache);
              }}
            />
          </div>
        </div>
      }
    </div>
  )
}

export default DrillThroughResult;
